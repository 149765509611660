<template>
  <div>
    <!-- 시나리오 목록 -->
    <c-table
      ref="table"
      title="LBL0001165"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="false"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='riskHazardPicture'">
          <q-btn 
            unelevated round dense
            size="7px"
            color="primary" 
            icon="wallpaper"
            @click.stop="openHazardPicture(props)" />
        </template>
        <template v-else-if="col.name==='picture'">
          <q-btn 
            unelevated round dense
            size="7px"
            color="primary" 
            icon="wallpaper"
            @click.stop="openImprPicture(props)" />
        </template>
        <template v-else-if="col.name==='ramRiskLevelName'">
          <q-chip text-color="white" :style="`background-color:${props.row.riskColor} !important;color:white;`" outline square>
            {{props.row[col.name]}}
          </q-chip>
        </template>
        <template v-else-if="col.name==='ramAfterRiskLevelName'">
          <q-chip text-color="white" :style="`background-color:${props.row.riskColorAfter} !important;color:white;`" outline square>
            {{props.row[col.name]}}
          </q-chip>
        </template>
        <template v-else-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="false"
            :requestContentsCols="requestContentsCols"
            tableKey="ramThirdAssessScenarioId"
            ibmTaskTypeCd="ITT0000013"
            ibmTaskUnderTypeCd="ITTU000015"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'thirdScenarioTL',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        merge: [
          { index: 0, colName: 'processName' },
        ],
        columns: [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'hazardDisasterTypeName',
            field: 'hazardDisasterTypeName',
            label: '재해유형',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardQuestionName',
            field: 'riskHazardQuestionName',
            // 유해위험요인<br/>(위험한 상황과 사건)
            label: 'LBL0001150',
            align: 'left',
            style: 'width:350px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            // 사진
            label: 'LBL0001175',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          // {
          //   name: 'relationLaw',
          //   field: 'relationLaw',
          //   // 관련법규
          //   label: 'LBL0001021',
          //   align: 'left',
          //   style: 'width:100px',
          //   sortable: false,
          // },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'ramRiskLevelName',
            field: 'ramRiskLevelName',
            label: '개선 전<br/>판단결과',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'custom'
          },
          {
            name: 'picture',
            field: 'picture',
            // 개선<br/>전/후<br/>사진
            label: 'LBL0001176',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'ramAfterRiskLevelName',
            field: 'ramAfterRiskLevelName',
            label: '개선 후<br/>판단결과',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'custom'
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 개선대책
            label: '개선대책',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        height: '600px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    requestContentsCols() {
      return ['riskHazardQuestionName'];
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.third.scenario.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    /* eslint-disable no-unused-vars */
    openHazardPicture(props) {
      this.popupOptions.title = 'LBL0001194'; // 유해위험요인(위험한 상황과 사건) 사진
      this.popupOptions.param = {
        ramThirdAssessScenarioId: props.row.ramThirdAssessScenarioId,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/third/action/thirdRiskHazardPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = 'LBL0001181'; // 개선 사진
      this.popupOptions.param = {
        ramThirdAssessScenarioId: props.row.ramThirdAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/third/action/thirdScenarioImprPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
